import React, { Component } from "react";

import Hero from "react-bulma-components/lib/components/hero/hero";
import Body from "react-bulma-components/lib/components/hero/components/hero-body";
import Container from "react-bulma-components/lib/components/container/container";
import Content from "react-bulma-components/lib/components/content/content";
import Heading from "react-bulma-components/lib/components/heading/heading";

import { Page } from "reusable-components";
import { ArrowLeft } from "reusable-components/ui/buttons";
import Logo from "../../components/brand/logo";
import Footer from "../../components/sections/footer";
import Author from "../../components/ui/author";
import HorizontalDivider from "../../components/ui/divider/horizontal";
import PublishDate from "../../components/ui/publish-date";
import RelatedArticles from "../../components/ui/tiles/related-articles";
import Styles from "./index.module.scss";


export default class BlogArticle extends Component {
	static propTypes = {};

	static defaultProps = {};

	render () {
		const {
			title,
			description,
			updated,
			linkedArticles,
			contentHtml,
			generalData,
			siteMetadata
		} = this.props.pageContext;

		return (
			<Page
				title={ `${ title } | ${ generalData.titleAppendix }` }
				description={ description }
				absoluteUrl={ siteMetadata.siteUrl }
				author={ siteMetadata.author }
				absoluteTeaserImagePath={ siteMetadata.siteUrl }
				language={ siteMetadata.language }
				location={ {
					...generalData.location,
					locationName: generalData.companyName,
				} }>

				<div className={ Styles.header }>
					<ArrowLeft
						renderAs={ "a" }
						href={ "/blog/" }
						className={ Styles.button }>
						Blog
					</ArrowLeft>

					<Heading
						textAlignment={ "centered" }
						renderAs={ "div" }>
						<Logo className={ Styles.logo } />
					</Heading>
				</div>

				<Container>
					<Hero
						size={ "medium" }
						textAlignment={ "centered" }
						className={ Styles.hero }>

						<Body className={ Styles.body }>
							<PublishDate
								date={ updated }
								className={ Styles.updatedTime }
							/>

							<Heading
								textAlignment={ "centered" }
								renderAs={ "h1" }
								className={ Styles.title }>
								<span>
									{ title }
								</span>
							</Heading>

							<Content
								textAlignment={ "centered" }
								className={ Styles.description }
								dangerouslySetInnerHTML={ { __html: description } }>
							</Content>
						</Body>
					</Hero>

					<HorizontalDivider
						className={ Styles.headingDivider }
					/>

					<Content
						className={ Styles.content }
						textSize={ 5 }
						textColor={ "dark" }
						dangerouslySetInnerHTML={ {
							__html: contentHtml
								.replace(/ {2}/g, " ")
								.replace(/( )?(&nbsp;)/g, " ")
						} }
					/>

					{ linkedArticles.length !== 0 &&
						<>
							<HorizontalDivider
								className={ "has-margin-bottom-xl" }
							/>

							<RelatedArticles ids={ linkedArticles.map(({ id }) => id) } />
						</>
					}

					<HorizontalDivider className={ "has-padding-top-xxl has-margin-top-xl" }/>

				</Container>

				<Footer />

				<Author />
			</Page>
		);
	}
}
