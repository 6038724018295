import React from "react";
import Container from "react-bulma-components/lib/components/container/container";
import Columns from "react-bulma-components/lib/components/columns/columns";
import Column from "react-bulma-components/lib/components/columns/components/column";
import Heading from "react-bulma-components/lib/components/heading/heading";

import ArticleTile from "../article-tile";
import Styles from "./index.module.scss";


const RelatedArticles = ({ ids = [] }) => {
	return (
		<Container className={ Styles.container }>
			<Heading
				textSize={ 5 }
				textAlignment={ "centered" }
				renderAs={ "p" }
				className={ "has-margin-bottom-lg" }>
				Weitere Artikel
			</Heading>

			<Columns centered marginless>

				{ ids.map(id =>
					<Column
						key={ id }
						size={ 6 }>
						<ArticleTile articleId={ id } />
					</Column>
				) }

			</Columns>
		</Container>
	);
};

export default RelatedArticles;